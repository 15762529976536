import * as React from "react"
import {
  Box,
  Heading,
  Text,
  Divider,
  Flex,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react"
import Layout from "../components/Layout"
import Jumbo from "../components/Jumbo"
import DonateButton from "../components/DonateButton"
import ShepherdsContainer from "../components/ShepherdsContainer"
import market2 from "../images/market2.jpg"

const IndexPage = () => {
  return (
    <Layout>
      <Jumbo imageUrl={market2}>
        <Heading size="3xl">Too many children are hungry.</Heading>
        <Text>
          Studies show that students do best when they have had plenty to eat.
          Too many children don’t have enough food at home. That’s why so many
          schools have food pantries.
        </Text>
        <Text>
          We have partnered with several schools in the county to help keep
          their food pantries stocked. Children in need usually only get
          breakfast and lunch at school as their only meals. The food pantries
          send them home each weekend with enough food to get them through until
          Monday.
        </Text>
        <Heading textShadow="0 0 15px white">You can help.</Heading>
      </Jumbo>
      <ShepherdsContainer>
        <Heading id="donate">Ways you can help</Heading>
        <Heading size="lg">Donate money</Heading>
        <Text>
          You can securely donate money via PayPal through the button below. We
          are a 501(c)3, so all donations are tax deductible.
        </Text>
        <DonateButton />
        <Text>
          You can mail a check or money order to us at the following address:
        </Text>
        <Box as="address">
          <Text>Tuskawilla Shepherds</Text>
          <Text>5626 Red Bug Lake Road</Text>
          <Text>Winter Springs, FL 32708</Text>
        </Box>
        <Heading size="lg">Donate food</Heading>
        <Text>You can drop off food at one of our drop off locations:</Text>
        <Flex>
          <Box as="address">
            <Text>Jean Arthur Associates</Text>
            <Divider />
            <Text>5626 Red Bug Lake Road</Text>
            <Divider />
            <Text>Winter Springs, FL 32708</Text>
          </Box>
          <Box as="address" ml="5">
            <Text>Tuskawilla United Methodist Church</Text>
            <Divider />
            <Text>3925 Red Bug Lake Road</Text>
            <Divider />
            <Text>Casselberry, FL 32707</Text>
          </Box>
        </Flex>
        <Heading size="lg">Food donation suggestion list</Heading>
        <Text>NO GLASS - NO PERISHABLE ITEMS</Text>
        <Text as="b">*High need for items in bold*</Text>
        <UnorderedList>
          <ListItem>
            <Text as="b">
              Protein items (beans, peanut butter, canned meats)
            </Text>
          </ListItem>
          <Text as="b">
            <ListItem>Canned fruit/vegetables</ListItem>
          </Text>
          <ListItem>Canned soup/ravioli/spaghetti</ListItem>
          <ListItem>Boxed pasta/macaroni and cheese</ListItem>
          <ListItem>Breakfast items (bars, cereal, oatmeal)</ListItem>
        </UnorderedList>
        <Text>Personal items can be included if desired</Text>
        <UnorderedList>
          <ListItem>Deodorant</ListItem>
          <ListItem>Toothbrushes/toothpaste</ListItem>
          <ListItem>Soap/shampoo</ListItem>
        </UnorderedList>
      </ShepherdsContainer>
    </Layout>
  )
}

export default IndexPage

export const Head = () => <title>Tuskawilla Shepherds</title>
